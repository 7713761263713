<template>
    <div class="py-4" style="position: relative">
        <div
            class="pr-5"
            :class="`${$vuetify.breakpoint.lgAndUp ? '' : 'd-flex justify-center' }`"
            :style="`right: 0; top: 0; ${$vuetify.breakpoint.lgAndUp ? 'position: absolute; width: 23%' : 'width: 100%' }`"
        >
            <img
                :width="`${$vuetify.breakpoint.lgAndUp ? '' : '500' }`"
                alt="products"
                src="/images/document_download.svg"
            />
        </div>
        <div :style="`${$vuetify.breakpoint.mdAndUp ? 'margin-left: 100px' : '' }`">
            <template v-for="(doc, i) in downloadDocuments">
                <a :href="`/documents/downloadsTab/${doc.filename}`" :key="i" style="text-decoration: none" target="_blank">
                    <div
                        class="d-flex align-center px-6"
                        style="cursor: pointer"
                    >
                        <v-card-title>
                            <v-icon v-if="doc.title" color="primary" left>mdi-file-document-outline</v-icon>
                            <div v-else>&nbsp;</div>
                            {{doc.title}}
                        </v-card-title>
                    </div>
                </a>
            </template>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    data() {
        return {
        }
    },
    components: {
    },
    computed: {
        ...mapGetters(['team', 'downloadDocuments', 'curPage', 'pages'])
    }
}
</script>

<style scoped>

</style>