<template>
  <v-app>
    <v-main>
        <div
            id="topOfPage"
            style="height: 100vh"
        >
            <Header class="white"/>
            <home v-if="curPage === 'home'"/>
            <products v-if="curPage === 'products'"/>
            <team v-if="curPage === 'team'"/>
            <find-a-broker v-if="curPage === 'findBroker'"/>
            <partner v-if="curPage === 'becomePartner'"/>
            <about v-if="curPage === 'about'"/>
            <documents-download v-if="curPage === 'downloads'"/>
            <documents-legal v-if="curPage === 'additionalDocs'"/>
            <smit-advisors v-if="curPage === 'smitAdvisors'"/>
            <news-main v-if="curPage === 'news'"/>
            <Footer/>
        </div>
    </v-main>
  </v-app>
</template>

<script>
import Home from "./components/Home";
import {mapGetters} from "vuex";
import Products from "./components/Products";
import Team from "./components/Team";
import Header from "./components/Header";
import FindABroker from "./components/FindABroker";
import Partner from './components/partner'
import Footer from './components/Footer'
import About from "./components/About";
import DocumentsLegal from "./components/Documents_Legal.vue";
import DocumentsDownload from "./components/Documents_Downloads.vue";
import SmitAdvisors from "./components/SmitAdvisors";
import NewsMain from "./components/News/NewsMain";

export default {
  name: 'App',
  components: {
      NewsMain,
      SmitAdvisors,
      About,
      Partner,
      FindABroker,
      Header,
      Products,
      Home,
      Team,
      Footer,
      DocumentsLegal,
      DocumentsDownload,
  },
  data() {
    return {

    }
  },
  watch: {
    curPage() {
        this.$nextTick(this.$vuetify.goTo(0))
    }
  },
  computed: {
    ...mapGetters(['curPage'])
  },
};
</script>