<template>
    <div class="d-flex flex-column pt-4" style="position: relative">
        <div
            :class="`${$vuetify.breakpoint.lgAndUp ? '' : 'd-flex justify-center' }`"
            :style="`width: 30%; ${$vuetify.breakpoint.lgAndUp ? 'position: absolute; right: 0; top: 0' : 'width: 100%' }`">
            <img
                :width="`${$vuetify.breakpoint.lgAndUp ? '500' : '400' }`"
                alt="Team"
                src="/images/team.svg"
            />
        </div>
        <div
            class="mx-md-6 mx-2"
            v-for="(division, i) in team"
            :key="i"
        >
            <div class="text-h4 primary--text pa-2">{{division.title}}</div>
            <div class="d-flex align-start flex-wrap my-5">
                <template v-for="(member, n) in division.members">
                    <div
                        class="d-flex flex-column justify-center align-center mb-5"
                        v-if="member !== '<br>'"
                        :key="n"
                        :style="`z-index: 99; ${$vuetify.breakpoint.mdAndUp ? 'width: 200px' : 'width: 50%' }`"
                    >
                        <v-card
                            hover
                            style="width: 115px; height: 115px; overflow: hidden; background-position: center; background-size: cover; border-radius: 70px; border: 3px solid white"
                            :style="`background-image: url('./avatars/${member.image || 'none.svg'}')`"
                        ></v-card>
                        <div class="mt-2">
                            <div
                                class="text-center px-2"
                                style="background: rgba(255, 249, 247, 0.75); border-radius: 5px"
                            >{{member.title}}</div>
                            <div
                                v-if="member.subTitle"
                                class="text-center caption mt-1 px-2"
                                style="background: rgba(255, 249, 247, 0.75); border-radius: 5px; white-space: pre"
                            >{{member.subTitle}}</div>
                            <div
                                v-if="member.email"
                                class="text-center caption mt-1 px-2"
                                style="background: rgba(255, 249, 247, 0.75); border-radius: 5px; white-space: pre"
                            >{{member.email}}</div>
                            <div
                                v-if="member.mobile"
                                class="text-center caption mt-1 px-2"
                                style="background: rgba(255, 249, 247, 0.75); border-radius: 5px; white-space: pre"
                            >{{member.mobile}}</div>
                        </div>
                    </div>
                    <div v-else :key="'br' + n" style="width: 100%"></div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {

    data() {
        return {
        }
    },

    components: {
    },

    computed: {
        ...mapGetters(['team'])
    }
}
</script>

<style scoped>
.home {
    background-image: url("/images/background_2.svg");
    background-position: bottom;
    background-size: contain;
}
</style>