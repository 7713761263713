<template>
  <div class="py-4" style="position: relative">
    <div
      class="pr-5"
      :class="`${$vuetify.breakpoint.lgAndUp ? '' : 'd-flex justify-center' }`"
      :style="`right: 0; top: 0; ${$vuetify.breakpoint.lgAndUp ? 'position: absolute; width: 20%' : 'width: 100%' }`"
    >
      <img
        :width="`${$vuetify.breakpoint.lgAndUp ? '' : '250' }`"
        alt="products"
        src="/images/documents.svg"
      />
    </div>
    <div :style="`${$vuetify.breakpoint.mdAndUp ? 'margin-left: 100px' : '' }`">
      <template v-for="(doc, i) in documents">
        <a v-if="doc.title" :href="`/documents/legalDocs/${doc.filename}`" :key="i + 'A'" style="text-decoration: none" target="_blank">
          <div
            class="d-flex align-center px-4"
            style="cursor: pointer"
          >
            <v-card-title>
              <v-icon
                v-if="doc.title"
                left
                color="primary"
              >mdi-file-document-outline</v-icon>
              {{ doc.title }}
            </v-card-title>
          </div>
        </a>
        <div v-else :key="i + 'B'">
          <div
            class="d-flex align-center px-4"
            style="cursor: pointer"
          >
            <v-card-title>
              <v-icon
                v-if="doc.title"
                left
                color="primary"
              >mdi-file-document-outline</v-icon>
              {{ doc.title }}
            </v-card-title>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
    }
  },
  components: {
  },
  computed: {
    ...mapGetters(['team', 'documents', 'curPage', 'pages'])
  }
}
</script>

<style scoped>

</style>