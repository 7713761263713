<template>
    <div class="d-flex flex-column pt-4 find-a-broker">
        <div
            :class="`d-flex align-center justify-space-around pb-4 ${ $vuetify.breakpoint.mdAndUp ? '' : 'flex-column pa-2' }`"
            style="width: 100%; opacity: 1"
        >
            <v-card
                id="map"
                :class="`${ $vuetify.breakpoint.mdAndUp ? 'ml-10 mr-5' : 'mx-2' }`"
                :style="`${ $vuetify.breakpoint.mdAndUp ? 'width: 50%' : 'width: 100%' }`"
            ></v-card>
            <div
                v-if="$vuetify.breakpoint.mdAndUp"
                class="pa-2 ml-5 mr-10"
                style="width: 50%"
            >
                <div class="d-flex align-center py-6">
                    <div class="mr-5">
                        <v-card
                            class="text-h4 secondary white--text d-flex align-center justify-center"
                            style="border-radius: 30px; width: 50px; height: 50px"
                        >1</v-card>
                    </div>
                    <div class="d-flex align-center flex-wrap flex-grow-1">
                        <v-btn
                            v-for="(type, i) in insuranceTypes"
                            :key="i"
                            class="mb-1 mr-1"
                            small
                            :elevation="selectedType !== type.val ? 0 : 2"
                            :color="selectedType !== type.val ? 'secondary lighten-5 primary--text' : 'primary'"
                            @click="selectedType = type.val"
                        >{{ type.description }}</v-btn>
                    </div>
                </div>
                <div class="d-flex align-center py-6">
                    <div class="mr-5">
                        <v-card
                            class="text-h4 secondary white--text d-flex align-center justify-center"
                            style="border-radius: 30px; width: 50px; height: 50px"
                        >2</v-card>
                    </div>
                    <div class="d-flex align-center flex-wrap flex-grow-1">
                        <v-btn
                            v-for="(type, i) in provinces"
                            :key="i"
                            class="mb-1 mr-1"
                            small
                            :elevation="selectedProvince !== type.val ? 0 : 2"
                            :color="selectedProvince !== type.val ? 'secondary lighten-5 primary--text' : 'primary'"
                            @click="selectedProvince = type.val"
                        >{{ type.description }}</v-btn>
                    </div>
                </div>
                <div class="d-flex align-center py-6">
                    <div class="mr-5">
                        <v-card
                            class="text-h4 secondary white--text d-flex align-center justify-center"
                            style="border-radius: 30px; width: 50px; height: 50px"
                        >3</v-card>
                    </div>
                    <div class="d-flex align-center flex-wrap">
                        <v-btn
                            v-for="(type, i) in cities"
                            :key="i"
                            class="mb-1 mr-1"
                            small
                            :elevation="selectedCity !== type.val ? 0 : 2"
                            :color="selectedCity !== type.val ? 'secondary lighten-5 primary--text' : 'primary'"
                            @click="selectedCity = type.val"
                        >{{ type.description }}</v-btn>
                    </div>
                </div>
            </div>
            <div
                v-if="!$vuetify.breakpoint.mdAndUp"
                class="ml-5 pa-2"
                style="width: 100%; z-index: 100"
            >
                <div class="d-flex align-center py-6">
                    <div class="text-h6" style="width: 120px">Insurance: </div>
                    <v-menu
                        bottom
                        offset-y
                        close-on-click
                        close-on-content-click
                        max-height="260"
                    >
                        <template v-slot:activator="{ attrs, on }">
                            <div class="mr-5">
                                <v-card
                                    v-on="on"
                                    v-bind="attrs"
                                    class="text-h4 secondary white--text d-flex align-center justify-center"
                                    style="border-radius: 30px; width: 50px; height: 50px"
                                >1</v-card>
                            </div>
                        </template>
                        <v-list
                            v-for="(type, i) in insuranceTypes"
                            :key="i"
                            class="pa-2"
                        >
                            <v-btn
                                block
                                height="40"
                                :elevation="selectedType !== type.val ? 0 : 2"
                                :color="selectedType !== type.val ? 'secondary lighten-5 primary--text' : 'primary'"
                                @click="insuranceType(type)"
                            >{{ type.description }}</v-btn>
                        </v-list>
                    </v-menu>
                    <div class="flex-grow-1 text-h6">{{ insuranceTypeSelected }}</div>
                </div>
                <div class="d-flex align-center py-6">
                    <div class="text-h6" style="width: 120px">Province: </div>
                    <v-menu
                        bottom
                        offset-y
                        close-on-click
                        close-on-content-click
                        max-height="260"
                    >
                        <template v-slot:activator="{ attrs, on }">
                            <div class="mr-5">
                                <v-card
                                    v-on="on"
                                    v-bind="attrs"
                                    class="text-h4 secondary white--text d-flex align-center justify-center"
                                    style="border-radius: 30px; width: 50px; height: 50px"
                                >2</v-card>
                            </div>
                        </template>
                        <v-list
                            v-for="(type, i) in provinces"
                            :key="i"
                            class="pa-2"
                        >
                            <v-btn
                                block
                                height="40"
                                :elevation="selectedProvince !== type.val ? 0 : 2"
                                :color="selectedProvince !== type.val ? 'secondary lighten-5 primary--text' : 'primary'"
                                @click="selectedProvince = type.val"
                            >{{ type.description }}</v-btn>
                        </v-list>
                    </v-menu>
                    <div class="flex-grow-1 text-h6">{{ selectedProvince || 'All' }}</div>
                </div>
                <div class="d-flex align-center py-6">
                    <div class="text-h6" style="width: 120px">City: </div>
                    <v-menu
                        bottom
                        offset-y
                        close-on-click
                        close-on-content-click
                        max-height="250"
                    >
                        <template v-slot:activator="{ attrs, on }">
                            <div class="mr-5">
                                <v-card
                                    v-on="on"
                                    v-bind="attrs"
                                    class="text-h4 secondary white--text d-flex align-center justify-center"
                                    style="border-radius: 30px; width: 50px; height: 50px"
                                >3</v-card>
                            </div>
                        </template>
                        <v-list
                            v-for="(type, i) in cities"
                            :key="i"
                            class="pa-2"
                        >
                            <v-btn
                                block
                                height="40"
                                :elevation="selectedCity !== type.val ? 0 : 2"
                                :color="selectedCity !== type.val ? 'secondary lighten-5 primary--text' : 'primary'"
                                @click="selectedCity = type.val"
                            >{{ type.description }}</v-btn>
                        </v-list>
                    </v-menu>
                    <div class="flex-grow-1 text-h6">{{ selectedCity || 'All' }}</div>
                </div>
            </div>
        </div>
        <v-divider></v-divider>
        <div class="d-flex justify-center align-center justify-lg-start align-lg-start  flex-wrap pt-4">
            <div
                :class="`${$vuetify.breakpoint.mdAndUp ? 'ma-2' : 'mx-1'}`"
                v-for="(broker, i) in selectedOfficeBrokers || filteredBrokers"
                :key="i"
            >
                <finad-a-broker-broker :broker="broker"/>
            </div>
        </div>
    </div>
</template>

<script>

import { Loader } from "@googlemaps/js-api-loader"
import {mapGetters} from "vuex";
import FinadABrokerBroker from "./FinadABrokerBroker";
const southAfrica = { lat: -28.78665314044337, lng: 25.084750277652518};
import MarkerClusterer from '@googlemaps/markerclustererplus'

export default {
    components: {
        FinadABrokerBroker,
    },
    data() {
        return {
            insuranceTypes: [
                {val: null, description: 'All'},
                {val: 'short', description: 'Short Term'},
                {val: 'life', description: 'Life'},
                {val: 'medical', description: 'Medical'}
            ],

            insuranceTypeSelected: 'All',

            selectedType: null,
            selectedProvince: null,
            selectedCity: null,
            map: null,
            selectedOffice: null,
        }
    },
    methods: {
        markerClicked(office) {
            this.selectedOffice = office;
            console.log('Marker Clicked', office)
        },
        insuranceType(insurance) {
            this.selectedType = insurance.val;
            this.insuranceTypeSelected = insurance.description;
        },
    },
    created() {
        const vm = this

        const loader = new Loader({
            apiKey: "AIzaSyAH5KxJ9adr97fcd8XKp87XJ3Fuh-6oQ8w",
            version: "weekly",
        });

        loader.load().then(() => {
            let mapEl = document.getElementById("map")


            // The map, centered at Uluru
            const map = new google.maps.Map(mapEl, {
                zoom: 5,
                center: southAfrica,
            });

            let retro = [
                { elementType: "geometry", stylers: [{ color: "#ebe3cd" }] },
                { elementType: "labels.text.fill", stylers: [{ color: "#523735" }] },
                { elementType: "labels.text.stroke", stylers: [{ color: "#f5f1e6" }] },
                {featureType: "administrative", elementType: "geometry.stroke", stylers: [{ color: "#c9b2a6" }],},
                {featureType: "administrative.land_parcel", elementType: "geometry.stroke", stylers: [{ color: "#dcd2be" }],},
                {featureType: "administrative.land_parcel", elementType: "labels.text.fill", stylers: [{ color: "#ae9e90" }],},
                {featureType: "landscape.natural", elementType: "geometry", stylers: [{ color: "#dfd2ae" }],},
                {featureType: "poi", elementType: "geometry", stylers: [{ color: "#dfd2ae" }],},
                {featureType: "poi", elementType: "labels.text.fill", stylers: [{ color: "#93817c" }],},
                {featureType: "poi.park", elementType: "geometry.fill", stylers: [{ color: "#a5b076" }],},
                {featureType: "poi.park", elementType: "labels.text.fill", stylers: [{ color: "#447530" }],},
                {featureType: "road", elementType: "geometry", stylers: [{ color: "#f5f1e6" }],},
                {featureType: "road.arterial", elementType: "geometry", stylers: [{ color: "#fdfcf8" }],},
                {featureType: "road.highway", elementType: "geometry", stylers: [{ color: "#f8c967" }],},
                {featureType: "road.highway", elementType: "geometry.stroke", stylers: [{ color: "#e98d58" }],},
                {featureType: "road.highway.controlled_access", elementType: "geometry", stylers: [{ color: "#e98d58" }],},
                {featureType: "road.highway.controlled_access", elementType: "geometry.stroke", stylers: [{ color: "#db8555" }],},
                {featureType: "road.local", elementType: "labels.text.fill", stylers: [{ color: "#806b63" }],},
                {featureType: "transit.line", elementType: "geometry", stylers: [{ color: "#dfd2ae" }],},
                {featureType: "transit.line", elementType: "labels.text.fill", stylers: [{ color: "#8f7d77" }],},
                {featureType: "transit.line", elementType: "labels.text.stroke", stylers: [{ color: "#ebe3cd" }],},
                {featureType: "transit.station", elementType: "geometry", stylers: [{ color: "#dfd2ae" }],},
                {featureType: "water", elementType: "geometry.fill", stylers: [{ color: "#b9d3c2" }],},
                {featureType: "water", elementType: "labels.text.fill", stylers: [{ color: "#92998d" }],},
            ]

            map.setOptions({ styles: retro });

            vm.map = map;

            // The marker, positioned at Uluru

            vm.offices.forEach(office => {
                office.marker = new google.maps.Marker({
                    position: office.gps,
                    map: map,
                    title: office.location,
                });

                const contentString =
                        `<div id="content">
                        <div id="siteNotice">
                        </div>
                        <h2 id="firstHeading" class="firstHeading">${office.location}</h2>
                        <div id="bodyContent" style="padding-top: 10px">
                            <div style="max-width: 500px">
                                ${
                    office.brokers.map((broker) => {
                        return `<div style="display: inline-block; width: 240px; margin: 4px">
                                    <div style="display: inline-block; box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%); width: 46px; height: 46px; border-radius: 23px;">
                                        <img src="${window.location.origin}/avatars/${broker.image || 'none.svg'}" width="46px" height="46px" style="border-radius: 23px;">
                                    </div>
                                    <div style="display: inline-block; padding-left: 10px; max-width: 190px">
                                        <div style="font-size: 14px">
                                            <b>${broker.title}</b>
                                        </div>
                                        <div style="font-size: 12px">
                                            ${broker.number || ''}
                                        </div>
                                        <div style="font-size: 12px">
                                            ${broker.email || ''}
                                        </div>
                                    </div>
                                </div>`
                    }).join('\n')
                        }
                            </div>
                        </div>
                        </div>`;
                const infowindow = new google.maps.InfoWindow({
                    content: contentString,
                });

                office.infoWindow = infowindow

                infowindow.addListener("closeclick", () => {
                    vm.selectedOffice = null
                });

                office.marker.addListener("click", () => {

                    vm.offices.forEach(off => {
                        if (off !== office)
                            off.infoWindow.close()
                    })

                    vm.markerClicked(office)
                    infowindow.open({
                        anchor: office.marker,
                        map,
                        shouldFocus: false,
                    });
                });
            })
        });
    },
    watch: {
        selectedType() {
            this.selectedProvince = null;
            this.selectedCity = null
        },
        selectedProvince() {
            this.selectedCity = null
        },
        filteredBrokers(val) {
            function onlyUnique(value, index, self) {
                return self.indexOf(value) === index;
            }
            let offices = val.map(obj => {
                return obj.office
            }).filter(onlyUnique);

            this.offices.forEach(obj => {
                if (!offices.includes(obj))
                    obj.marker.setMap(null)
                else
                    obj.marker.setMap(this.map)
            })

            if (!offices.length) {
                this.map.setCenter(southAfrica);
            } else {
                // Example values of min & max latlng values
                let lat_min = offices.reduce((val, office) => {return office.gps.lat < val ? office.gps.lat : val}, offices[0].gps.lat);
                let lat_max = offices.reduce((val, office) => {return office.gps.lat > val ? office.gps.lat : val}, offices[0].gps.lat);
                let lng_min = offices.reduce((val, office) => {return office.gps.lng < val ? office.gps.lng : val}, offices[0].gps.lng);
                let lng_max = offices.reduce((val, office) => {return office.gps.lng > val ? office.gps.lng : val}, offices[0].gps.lng);

                this.map.setCenter(new google.maps.LatLng(
                        ((lat_max + lat_min) / 2.0),
                        ((lng_max + lng_min) / 2.0)
                ));
                this.map.fitBounds(new google.maps.LatLngBounds(
                        //bottom left
                        new google.maps.LatLng(lat_min, lng_min),
                        //top right
                        new google.maps.LatLng(lat_max, lng_max)
                ));
            }
            this.selectedOffice = null
        }
    },
    computed: {
        ...mapGetters(['offices', "brokers"]),
        selectedOfficeBrokers() {
            if (!this.selectedOffice) {
                return null
            } else {
                return this.selectedOffice.brokers.map(obj => {
                    return {
                        ...obj,
                        office: this.selectedOffice
                    }
                })
            }
        },
        brokersFull() {
            let officesObject = this.offices
                .reduce((obj, office) => {
                    obj[office.key] = office
                    return obj
                }, {});

            return this.brokers.map(obj => {

                if (!officesObject[obj.officeKey]) {
                    console.log(obj, officesObject)
                }

                return {
                    ...obj,
                    office: officesObject[obj.officeKey]
                }
            })
        },
        provinces() {
            const provincesArr = this.brokersFull
                .filter(brok => !this.selectedType  ? true : brok.insuranceTypes.includes(this.selectedType))
                .reduce((list, item) => {
                    return list.includes(item.office.province) ? list : list.concat(item.office.province);
                }, [])
                .map(item => {
                    return {val: item, description: item};
                })

            provincesArr.unshift({val: null, description: 'All'});

            return provincesArr;
        },
        cities() {
            const citiesArr = this.brokersFull
                .filter(brok => !this.selectedType  ? true : brok.insuranceTypes.includes(this.selectedType))
                .filter(brok => !this.selectedProvince  ? true : brok.office.province === this.selectedProvince)
                .reduce((list, item) => {
                    return list.includes(item.office.city) ? list : list.concat(item.office.city)
                }, [])
                .map(item => {
                    return {val: item, description: item};
                })

            citiesArr.unshift({val: null, description: 'All'});

            return citiesArr;
        },
        filteredBrokers() {
            return this.brokersFull
                    .filter(brok => !this.selectedType  ? true : brok.insuranceTypes.includes(this.selectedType))
                    .filter(brok => !this.selectedProvince  ? true : brok.office.province === this.selectedProvince)
                    .filter(brok => !this.selectedCity  ? true : brok.office.city === this.selectedCity)
                    .sort((a,b) => a.title > b.title ? 1 : -1)
        },
    }
}
</script>

<style scoped>
.find-a-broker {
    /*background-image: url("../assets/find_a_broker.svg");*/
    /*background-position: top right;*/
    /*background-size: 300px;*/
}
#map {
    height: 400px;
    /* The height is 400 pixels */
    width: 100%;
    /* The width is the width of the web page */
}
</style>