<template>
    <div ref="topElement">
        <div
            class="mx-2 mx-md-0 d-flex align-top justify-center home"
            style="padding-bottom: 10%; margin-bottom: -50px"
        >
            <div
                class="pa-2"
                style="max-width: 800px; width: 100%"
            >
                <div class="text-h4 font-weight-black primary--text pa-2">Get a Personalised Insurance Quote</div>
                <v-card
                    :elevation="15"
                    class="pa-5"
                >
                    <template v-if="!mailSent">
                        <v-form
                            ref="sendMail"
                            :disabled="sendingMail"
                            @submit.prevent.stop
                            class="fill-height of-y"
                        >
                            <div class="pb-2 d-md-flex">
                                <v-autocomplete
                                    :items="[ 'Mr', 'Ms', 'Mrs', 'Miss', 'Dr', 'Prof', 'Rev', 'Pas']"
                                    label="Title"
                                    class="mx-2 d-md-flex"
                                    :rules="[validationRules.required]"
                                    v-model="title"
                                ></v-autocomplete>
                                <v-text-field
                                    class="flex-grow-1 mx-2"
                                    label="Name"
                                    placeholder="e.g. John"
                                    :rules="[validationRules.required]"
                                    v-model="name"
                                ></v-text-field>
                                <v-text-field
                                    class="flex-grow-1 mx-2"
                                    label="Surname"
                                    placeholder="e.g. Smith"
                                    :rules="[validationRules.required]"
                                    v-model="surname"
                                ></v-text-field>
                            </div>
                            <div class="pb-2 d-md-flex">
                                <v-text-field
                                    class="flex-grow-1 mx-2"
                                    label="Contact No."
                                    placeholder="e.g. 015 306 0087"
                                    :rules="[validationRules.required, validationRules.cellNo]"
                                    v-model="contactNo"
                                ></v-text-field>
                                <v-text-field
                                    class="flex-grow-1 mx-2"
                                    label="Email Address"
                                    placeholder="e.g. info@smitk.co.za"
                                    :rules="[validationRules.required, validationRules.email]"
                                    v-model="contactEmail"
                                ></v-text-field>
                                <v-text-field
                                    class="flex-grow-1 mx-2"
                                    label="RSA ID / Passport No."
                                    placeholder="e.g. 9110165165094"
                                    :rules="[validationRules.required, validationRules.min13Characters]"
                                    v-model="idNo"
                                ></v-text-field>
                            </div>
                            <div class="d-flex justify-center justify-md-end mb-3">
                                <v-btn
                                    large
                                    color="primary"
                                    @click="sendEmail"
                                    :disabled="sendingMail"
                                    :loading="sendingMail"
                                >
                                    <v-icon left>mdi-phone</v-icon>
                                    Give me a call
                                </v-btn>
                            </div>
                        </v-form>
                        <div class="caption grey--text">
                            We understand how important your privacy is and will protect your personal information. By providing your personal information you are agreeing that we may process it to furnish you with a quote. Also note that some advisors work under supervision.  This is to ensure that you’ll get nothing but awesome service, all of the time! Premiums quoted are risk profile dependent and reviewed annually based on risk profile and economic factors. Ts, Cs and limits apply. It is important to provide us with correct information, otherwise it could affect the outcome of your claim.
                        </div>
                    </template>
                    <template v-else>
                        <div class="d-flex align-center">
                            <img alt="We Will Be In Contact" src="/images/wait.svg" width="50%">
                            <div
                                class="text-center text-h5 primary--text pa-2"
                                style="width: 50%"
                            >
                                Thank you
                                <br>
                                <br>
                                We will be in contact shortly
                            </div>
                        </div>
                    </template>
                </v-card>
            </div>
        </div>
        <div :class="`d-flex align-center justify-center ${$vuetify.breakpoint.mdAndUp ? 'bannerLarge' : 'bannerSmall' }`">
<!--        <div class="d-flex align-center justify-center">-->
            <div class="my-2 pa-8" style="max-width: 1275px">
                <div class="text-h4 text-center pb-4">Why Us?</div>
                <div class="font-weight-light pt-2 d-flex align-center">
                    <v-icon left>mdi-circle-small</v-icon>
                    At Naude Brokers, our commitment to honesty and integrity shines through as we offer professional and precise guidance. Our team of qualified experts diligently manages your risk, aligning it with the latest market trends.
                </div>
                <div class="font-weight-light pt-2 d-flex align-center">
                    <v-icon left>mdi-circle-small</v-icon>
                    With Naude Brokers, we prioritize delivering the best coverage your money can buy, all while keeping premiums affordable. Our world-class service complements our dedication to placing your needs front and center, recognizing you, the client, as the cornerstone of our business.
                </div>
                <div class="font-weight-light pt-2 d-flex align-center">
                    <v-icon left>mdi-circle-small</v-icon>
                    Our operations strictly adhere to government regulations, ensuring we stay current with the ever-evolving legislative landscape. Your personal information and interests remain safeguarded at all times.
                </div>
                <div class="font-weight-light pt-2 d-flex align-center">
                    <v-icon left>mdi-circle-small</v-icon>
                    We go above and beyond to make a positive impact on our clients, especially during the claims process. Recognizing the urgency of settling claims swiftly, we actively seek solutions to expedite the process in the most efficient manner possible.
                </div>
            </div>
        </div>
        <div style="margin-bottom: 10px"></div>
    </div>
</template>

<script>

// import Footer from './Footer'
import {mapGetters} from "vuex";

export default {
    components: {
        // Footer: Footer,
    },
    data() {
        return {
            validationRules: {
                required: value => !!value || 'Required Value',
                min13Characters: value => !!value && value.length >= 13 || 'Minimum 13 Characters',
                notZero: value => value === '0' || value === '' || value == null ? 'Value can not be zero' : true,
                notNull: value => value !== null || 'Required Value',
                min6Characters: value => value !== null && value.split('').length >= 6 || 'Minimum 6 characters',
                specialCharacter: value => value !== null && /[^A-Za-z0-9]/.test(value) || 'Minimum 1 special character (eg. !@#$%)',
                upperCase: value => value !== null && /[A-Z]/.test(value) || 'Minimum 1 uppercase character',
                lowerCase: value => value !== null && /[a-z]/.test(value) || 'Minimum 1 lowercase character',
                numeric: value => value !== null && /[0-9]+/.test(value) || 'Minimum 1 numerical character',
                numberOnly: value => value !== null && /^[\d\\.]+$/.test(value) || 'Only numbers allowed',
                wholeNumberOnly: value => value !== null && /^[\d]+$/.test(value) || 'Only whole numbers allowed',
                email: value => {
                    if (!!value && value !== null && value.length > 0) {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(value) || 'Invalid Email Address';
                    } else {
                        return 'Please enter a valid email address'
                    }
                },
                cellNo: value => value !== null && /[0-9+ ][^A-Za-z]{9,}/.test(value) || 'Please enter a valid phone number',
            },
            sendingMail: false,
            mailSent: false,
            title: null,
            name: null,
            surname: null,
            contactNo: null,
            contactEmail: null,
            idNo: null,
        }
    },
    methods: {
        sendEmail() {
            if (this.$refs.sendMail.validate()) {
                this.sendingMail = true;
                let mailBody = `<table style="font-family: sans-serif;">
                    <tr>
                        <td style="padding: 8px" colSpan="2" style="font-size: 18px">New Quote Request From Website</td>
                    </tr>
                    <tr>
                        <td style="padding: 8px"><strong>Full Name:</strong></td>
                        <td style="padding: 8px">${this.title} ${this.name} ${this.surname}</td>
                    </tr>
                    <tr>
                        <td style="padding: 8px"><strong>Contact No:</strong></td>
                        <td style="padding: 8px">${this.contactNo}</td>
                    </tr>
                    <tr>
                        <td style="padding: 8px"><strong>Contact Email:</strong></td>
                        <td style="padding: 8px">${this.contactEmail}</td>
                    </tr>
                    <tr>
                        <td style="padding: 8px"><strong>ID - Passport No:</strong></td>
                        <td style="padding: 8px">${this.idNo}</td>
                    </tr>
                </table>`
                Email.send({
                    SecureToken : "0a0cd794-a296-40fe-b688-973763c30498",
                    // To : this.quotesEmailAddress,
                    To : 'wernerd@tasklogix.co.za',
                    From : "website@smitk.co.za",
                    Subject : "[SK WEBSITE] New Quote",
                    Body : mailBody
                }).then(() => {
                    this.sendingMail = false;
                    this.mailSent = true;
                });
            }
        }
    },
    computed: {
        ...mapGetters(['quotesEmailAddress'])
    },
}
</script>

<style scoped>
.home {
    background-image: url("/images/background.svg");
    background-position: bottom;
    background-size: contain;
}
.bannerLarge {
    //background: linear-gradient(150deg, var(--v-primary-base) 0%, var(--v-secondary-base) 100%);
    min-height: 400px;
    overflow-y: visible;
    padding-top: 70px;
    padding-bottom: 70px;
}
.bannerSmall {
    //background: linear-gradient(150deg, var(--v-primary-base) 0%, var(--v-secondary-base) 100%);
    min-height: 400px;
    overflow-y: visible;
    padding-top: 70px;
    padding-bottom: 70px;
}
</style>
