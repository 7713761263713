<template>
    <v-app-bar
        app
        dark
        shrink-on-scroll
        :height="fullHeight"

        class="black--text pa-0 ma-0 white"
        style="z-index: 1000"
    >
        <div
            class="d-flex flex-column"
            style="height: 100%; width: 100%"
        >
            <div
                class="d-flex flex-grow-1 gradient"
                style="width: 100%"
            >
                <div class="fill-height d-flex justify-center align-center pa-1">
                    <img
                        height="80%"
                        alt="logo"
                        src="/images/Naude_Brokers_Dark.svg"
                    />
                </div>

                <div
                    v-if="$vuetify.breakpoint.mdAndUp"
                    class="flex-grow-1 green--text d-flex justify-center flex-column pl-11"
                >
                    <template v-if="$parent.styles.paddingTop === `${fullHeight}px`">
                        <div class="d-flex justify-end align-center px-2">

                            <div class="font-weight-regular flex-grow-1 d-flex flex-column justify-center align-center white--text">
                                <div>
                                    <div class="text-h5">Short-Term Insurance Specialists</div>
                                    <div class="subtitle-1">Authorised Financial Service Provider</div>
                                </div>
                            </div>

                            <div class="text-h5 font-weight-regular pr-2 white--text">
                               Give us a <b>call</b> on <b>015 306 0087</b> or
                            </div>
                            <v-btn color="white" class="ma-0 pa-0 px-2 mr-2 primary--text" @click="$vuetify.goTo('#contactUs')">
                                <span class="text-h5 font-weight-bold" style="text-transform: none">Let us call you</span>
                            </v-btn>
                        </div>
                        <div class="secondary my-3" style="height: 2px"></div>
                    </template>
                    <div class="d-flex pl-4">
                        <v-btn
                            v-for="(page, i) in pages"
                            :key="i"
                            :text="page.id !== curPage"
                            :outlined="page.id === curPage"
                            @click="setCurPage(page.id)"
                            class="mr-1"
                        >
                            <span style="text-transform: none">{{page.title}}</span>
                        </v-btn>
                    </div>
                </div>
                <div v-else class="d-flex justify-end pr-5 align-center" style="width: 100%">
                    <v-menu
                        bottom
                        offset-y
                        close-on-content-click
                        close-on-click
                    >
                        <template v-slot:activator="{ attrs, on }">
                            <v-btn
                                v-on="on"
                                v-bind="attrs"
                                fab
                                text
                                elevation="0"
                            >
                                <v-icon large>mdi-menu</v-icon>
                            </v-btn>
                        </template>
                        <v-list
                            v-for="(btn, i) in pages"
                            :key="i"
                            class="pa-2"
                        >
                            <v-btn
                                text
                                width="100%"
                                @click="setCurPage(btn.id)"
                            >{{ btn.title }}</v-btn>
                        </v-list>
                    </v-menu>
                </div>
            </div>
        </div>
    </v-app-bar>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    data() {
        return {
            console: console,
        }
    },
    methods: {
        ...mapActions(["setCurPage"])
    },
    computed: {
        ...mapGetters(["curPage", "pages"]),
        fullHeight() {
            // return 128
            return 150;
        },
        getScreenWidth() {
            return window.screen.width;
        }
    },
}
</script>

<style scoped>

.gradient {
    background: linear-gradient(
        150deg,
        rgba(0,0,0,0) 0%,
        var(--v-secondary-base) 0%,
        var(--v-primary-base) 100%,
        rgba(0,0,0,0) 0%
    );
}

</style>