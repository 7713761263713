import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {customProperties: true},
        themes: {
            options: {
                customProperties: true,
            },
            light: {
                primary: '#6d8aaa',
                secondary: '#4c6076',
            },
        }
    }
});
